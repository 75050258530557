<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">企業成員管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span>
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
        <!-- <button
          v-show="!grid.isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button> -->
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-10 mt-10">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <!-- <button
            v-show="!grid.isEditing"
            class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
            @click="grid.addNewRow"
          >
            <UserPlusIcon icon="plus" type="fas" class="w-4 h-4 mr-1" />
            新增成員
          </button> -->
          <a
            href="javascript:void(0)"
            @click="router.push('/member-import')"
            class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          >
            <LogInIcon icon="plus" type="fas" class="w-4 h-4 mr-1" />
            新增成員
          </a>
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <!-- <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-import" class="w-4 h-4 mr-2" /> 匯入
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button> -->
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a> -->
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <!-- <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
             
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @edit="onGridEdit"
          @removeSelectedRows="hideBatchDropDown()"
        >
          <!-- <template #operate-edit="{ }">
            <div class="flex justify-center">
              <a
                href="javascript:;"
                data-toggle="modal"
                data-target="#member-edit-modal"
                class="button rounded-full bg-blue-600 text-white mr-2"
              >
                <FontAwesome icon="highlighter" class="w-4 h-4" />
              </a>                   
            </div>
          </template> -->

          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
              <template #column-product="{ data }">
                <select-box
                  v-bind="productSelectorOptions"
                  v-model="data.productIDs"
                />
              </template>
              <template #form-position="{data}">
                <div class="flex">
                  <div>
                    <vxe-select
                      :options="positionList"
                      v-model="data.position"
                      transfer
                      placeholder="請選擇成員職務權限"
                      clearable
                    />
                  </div>
                  <div class="px-2"><a href="#">查看權限比較</a></div>
                </div>
              </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button
              type="submit"
              status="primary"
              content="確認"
              @click="
                $refs.form.validate(errMap => {
                  if (errMap === undefined) $refs.form.dispatchEvent('submit');
                })
              "
            ></vxe-button>
            <vxe-button
              type="reset"
              content="重置"
              @click="$refs.form.dispatchEvent('reset')"
            ></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div
              v-for="(option, index) in column.filters"
              :key="index"
              class="flex item-center justify-center"
            >
              <input
                type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                >
                  {{ option.label }}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
  <MemberModal />
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, {
  computed,
  defineComponent,
  ref,
  onMounted
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import CheckBoxList from "@/cloudfun/components/CheckBoxList.vue";
import { integer } from "@vuelidate/validators";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import MemberModal from "@/components/member-edit-modal/Main.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Grid,
    CheckBoxList,
    SelectBox,
    MemberModal
  },
  setup() {
    const model = CloudFun.current?.model;
    const router = useRouter();
    const grid = ref<any>({});
    var printColumns = [{ field: "Name" }, { field: "Description" }];
    const positionList = ref<any>([]);
    const auth = ref<any>({});
    const position = [
      {
        text: "負責人",
        value: "1"
      },
      {
        text: "特助",
        value: "2"
      },
      {
        text: "產品經理",
        value: "3"
      },
      {
        text: "業務",
        value: "4"
      }
    ];

    const gridOptions: GridOptions = {
      id: "member",
      title: "成員",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: "角色清單",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "角色清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "account",
          title: "帳號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "nick_Name",
          title: "暱稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "contact_Title",
          title: "職稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "contact_Tel",
          title: "聯絡電話",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          visible: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "contact_Email",
          title: "業務信箱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "position",
          title: "權限",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          formatter: ({ cellValue }) => {
            let item = null;
            if (cellValue) {
              item = position.find(e => e.value === cellValue.toString())?.text;
            }
            return item ? item : "";
          }
        },
        {
          field: "status",
          title: "狀態",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        // {
        //   field: 'remark',
        //   title: '備註',
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   resizable: false,
        //   editRender: {
        //     name: '$input',
        //     immediate: true,
        //     attrs: { type: 'text' }
        //   }
        // },
        {
          field: "PermissionValues",
          visible: false
        }
      ],
      promises: {
        query: model
          ? params =>
              model.dispatch("member/query", {
                pageSize: params.pageSize,
                page: params.page,
                search: "null"
              })
          : undefined,
        queryAll: model ? () => model.dispatch("member/query") : undefined,
        save: model
          ? params => model.dispatch("member/save", params)
          : undefined,
        insert: model
          ? data => model.dispatch("member/insert", data)
          : undefined,
        delete: model ? id => model.dispatch("member/delete", id) : undefined,
        update: model
          ? data => model.dispatch("member/update", data)
          : undefined
      },
      modalConfig: { showFooter: true, width: "500" }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: "right",
      items: [
        {
          field: "contact_Email",
          title: "業務信箱",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        // {
        //   field: "name",
        //   title: "姓名",
        //   span: 24,
        //   itemRender: {
        //     name: "$input",
        //     props: { placeholder: "請輸入文字", readonly: true },
        //     attrs: { type: "text" }
        //   }
        // },
        {
          field: "contact_Title",
          title: "職稱",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "contact_Tel",
          title: "電話",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        // {
        //   field: 'account',
        //   title: '帳號',
        //   span: 24,
        //   itemRender: {
        //     name: '$input',
        //     props: { placeholder: '請輸入文字', readonly: true },
        //     attrs: { type: 'text' }
        //   }
        // },
        {
          field: "position",
          title: "權限職位",
          span: 24,
          slots: { default: "form-position" }
          // itemRender: {
          //   name: "$select",
          //   options: []
          // }
        },
        {
          field: "enable",
          title: "狀態",
          span: 24,
          itemRender: {
            name: "$select",
            options: [
              { label: "停用", value: false },
              { label: "已啟用", value: true }
            ]
          }
        }
        // {
        //   field: 'contact_Title',
        //   title: '職稱',
        //   span: 12,
        //   itemRender: {
        //     name: '$input',
        //     props: { placeholder: '請輸入文字', clearable: true },
        //     attrs: { type: 'text' }
        //   }
        // },

        // {
        //   title: '負責產品',
        //   span: 24,
        //   slots: { default: 'column-product' }
        // },
        // {
        //   field: 'remark',
        //   title: '備註',
        //   span: 24,
        //   itemRender: {
        //     name: '$input',
        //     props: { placeholder: '請輸入文字', clearable: true },
        //     attrs: { type: 'text' }
        //   }
        // },
        // {
        //   title: "權限",
        //   span: 24,
        //   slots: { default: "form-permissions" }
        // }
      ],
      rules: {
        position: [{ type: "string", required: true }],
        contact_Title: [{ type: "string", required: true }],
        contact_Tel: [
          {
            type: "string",
            message: "格式錯誤",
            required: false,
            pattern: new RegExp(
              "^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$"
            )
          }
        ],
        contact_Email: [
          {
            message: "格式錯誤",
            required: true,
            pattern: new RegExp(
              "^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$"
            )
          }
        ]
      }
    };

    const permissionItems = computed(() => {
      const items: any[] = [];
      for (const permission of Object.values(
        model?.enums.SystemPermission || {}
      )) {
        if (permission.GroupName != null) {
          let group = items.find(e => e.name === permission.GroupName);
          if (!group)
            items.push(
              (group = { name: permission.GroupName, items: [], order: 0 })
            );
          group.items.push({
            name: permission.Name,
            value: permission.Value,
            order: permission.Order
          });
          if (group.order < permission.Order) group.order = permission.Order;
        } else
          items.push({
            name: permission.Name,
            value: permission.Value,
            order: permission.Order
          });
      }
      return items.sort((a: any, b: any) => a.order - b.order);
    });

    const getAuth = () => {
      return model
        ?.dispatch("user/auth")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    onMounted(async () => {
      auth.value = await getAuth();
      if (!auth.value.isBuyer) router.push("/company-not-vendor");
      const positionData = await model?.dispatch("option/find", [
        "Vendor_Position"
      ]);

      if (positionData) {
        positionList.value = Object.values(positionData.Vendor_Position).map(
          (item: any) => {
            return { label: item.text, value: parseInt(item.value) };
          }
        );
      }
    });

    const productSelectorOptions: SelectBoxOptions = {
      rowId: "productID",
      transfer: true,
      placeholder: "選擇",
      textField: "name",
      valueField: "productID",
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value => model!.dispatch("product/find", value), // eslint-disable-line
        query: params =>
          model!.dispatch("product/query", {
            pageSize: params.pageSize,
            page: params.page
          }) // eslint-disable-line
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      permissionItems,
      positionList,
      position,
      productSelectorOptions,
      auth,
      router
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.memberID
        ? await this.$model.dispatch("member/find", row.memberID)
        : undefined;
      if (entity) {
        Object.assign(row, entity);
      }
      callback();
    }
  }
});
</script>
