<template>
  <div
    id="member-edit-modal"
    class="modal flex justify-center items-center pointer-events-none"
  >
    <div
      class="modal__content modal__content--xl max-h-screen overflow-y-scroll sm:overflow-y-visible relative w-screen p-6 max-w-screen-lg pointer-events-auto"
    >
      <a
        data-dismiss="modal"
        href="javascript:;"
        class="absolute right-0 top-0 mt-6 mr-6"
      >
        <xIcon class="w-8 h-8 text-gray-500" />
      </a>

      <div class="">
        <div class="font-bold text-2xl xl:text-3xl my-3">編輯成員</div>
        <div class="p-3 border border-theme-5">
          <div class="  mb-5">
            <div class=" lg:grid grid-cols-2 gap-5">
              <div class="">
                <div class=" sm:flex mb-5">
                <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                  公司名稱
                </div>
                <div class=" w-full">
                  林ＸＸ
                </div>
                </div>
                <div class=" sm:flex mb-5">
                  <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                    暱稱
                  </div>
                  <div class=" w-full ">
                    業務
                  </div>
                </div>
                <div class=" sm:flex mb-5">
                  <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                    職稱
                  </div>
                  <div class="w-full">
                    業務
                  </div>
                </div>
                <div class=" sm:flex mb-5">
                  <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                    業務信箱
                  </div>
                  <div class=" w-full ">
                    asd123123@gmail.com
                  </div>
                </div>
              </div>
              <div class="">
                <div class=" sm:flex mb-5">
                  <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                    啟用狀態
                  </div>
                  <div class="w-full">
                  <select class="intro-x input input--lg border border-gray-300 w-full mt-2 sm:mt-0" name="" id="">
                    <option value="">已啟用</option>
                    <option value="">停用</option>
                  </select>
                  </div>
                  <!-- <CfInputBox
                    label="啟用狀態"
                    name="name"
                    placeholder="啟用狀態"
                    type="text"
                  ></CfInputBox> -->
                </div>
                <div class=" sm:flex mb-5">
                   <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                      權限職位
                    </div>
                    <div class="w-full">
                    <select class="intro-x input input--lg border border-gray-300 w-full mt-2 sm:mt-0" name="" id="">
                      <option value="">業務</option>
                      <option value="">經理</option>
                    </select>
                  </div>
                  <!-- <CfInputBox
                    label="權限職位"
                    name="name"
                    placeholder="權限職位"
                    type="text"
                  ></CfInputBox> -->
                </div>
                <div class=" sm:flex mb-5">
                  <CfInputBox
                    label="備註"
                    name="name"
                    placeholder="備註"
                    type="text"
                  ></CfInputBox>
                </div>
                <div class=" sm:flex mb-5">
                  <CfInputBox
                    label="原因"
                    name="name"
                    placeholder="原因"
                    type="text"
                  ></CfInputBox>
                </div>
              </div>
            </div>
          </div>
          
          <div class="">
            <div class="overflow-x-auto scrollbar-hidden mt-2 memberEdit-wrap">
              <grid
                ref="grid"
                v-bind="gridOptions"
                @removeSelectedRows="hideBatchDropDown()"
              >
                <template #image_column="{ }">
                  <img
                    src="@/midone/assets/images/placeholders/200x200.jpg"
                    class=" my-2"
                  />
                </template>
                <template #itemDelete="{ }">
                  <div>
                    <a
                      href="javascript:;"
                      class=" inline-flex items-center"
                      @click="alertDelete()"
                    >
                      <DeleteIcon class="w-6 h-6 text-theme-10" />
                    </a>
                  </div>
                </template>
                
              </grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import CloudFun, { computed, defineComponent, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import CheckBoxList from '@/cloudfun/components/CheckBoxList.vue'
import ReplyModal from '@/components/reply-modal/Main.vue'
import InputBox, { InputBoxOptions } from "@/cloudfun/components/InputBox.vue"
import CfInputBox from "@/cloudfun/components/CfInputBoxHorizontal.vue"

export default defineComponent({
  components: {
    Grid,
    CheckBoxList,
    ReplyModal,
    CfInputBox
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [{ field: 'Name' }, { field: 'Description' }]

    const gridOptions: GridOptions = {
      id: 'memberEdit',
      title: '負責產品',
      multiselect: true,
      canUpdate: false,
      canDelete: false,
      canRead: false,
      canCreate: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: '負責產品',
        columns: printColumns,
        modes: ['current', 'selected', 'all']
      },
      exportConfig: {
        filename: '負責產品',
        type: 'csv',
        types: ['html', 'csv'],
        mode: 'all',
        modes: ['current', 'selected', 'all'],
        columns: printColumns
      },
      columns: [
        {
          field: 'id',
          title: '產品編號',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          align: "center",
          editRender: {
            name: '$input',
            immediate: true,
            attrs: { type: 'text' }
          },
        },
        {
          field: 'name',
          title: '產品名稱',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          align: "center",
          editRender: {
            name: '$input',
            immediate: true,
            attrs: { type: 'text' }
          },
        },
        {
          field: 'class',
          title: '產品類別',
          type: 'html',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          align: "center",
          editRender: {
            name: '$input',
            immediate: true,
            attrs: { type: 'text' }
          },
        },
        {
          field: 'serve',
          title: '服務類別',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          align: "center",
          editRender: {
            name: '$input',
            immediate: true,
            attrs: { type: 'text' }
          },
        },
        { field: 'image', 
          title: '產品預覽', 
          align: "center",
          slots: { default: 'image_column' } 
        },
        { field: 'itemDelete', 
          title: '取消指派', 
          align: "center",
          slots: { default: 'itemDelete' } 
        },
      ],
      promises: {
        // query: model
        //   ? (params) => model.dispatch('memberEdit/query', { pageSize: params.pageSize, page: params.page })
        //   : {"data":[{id: 1, name: 'memberEdit1'}], "intialPage":1,"page":1,"pageSize":10,"totalCount":1,"pageCount":1},
        query: () => {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve({
                "data":[
                  {
                    id: 1, 
                    name: 'product1',
                    class: "類別1 類別2",
                    serve: "類型1",
                  },
                  {
                    id: 2, 
                    name: 'product2',
                    class: "類別3",
                    serve: "類型2",
                  },
                ],
                "totalCount":1,
              });
            }, 100);
          });
        },
        queryAll: model ? () => model.dispatch('memberEdit/query') : undefined,
        save: model
          ? (params) => model.dispatch('memberEdit/save', params)
          : undefined
      },
      modalConfig: { showFooter: true }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 60,
      titleAlign: 'right',
      items: [
        {
          field: 'date',
          title: '時間',
          span: 24,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', clearable: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'product',
          title: '詢問產品',
          span: 24,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', clearable: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'buyer',
          title: '洽詢買家',
          span: 24,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', clearable: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'times',
          title: '洽談次數',
          span: 24,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', clearable: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'state',
          title: '狀態',
          span: 24,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', clearable: true },
            attrs: { type: 'text' }
          }
        },
      ],
      rules: {
        Name: [{ type: 'string', required: true }]
      }
    }

    const permissionItems = computed(() => {
      const items: any[] = []
      for (const permission of Object.values(model?.enums.SystemPermission || {})) {
        if (permission.GroupName != null) {
          let group = items.find(e => e.name === permission.GroupName)
          if (!group) items.push(group = { name: permission.GroupName, items: [], order: 0 })
          group.items.push({ name: permission.Name, value: permission.Value, order: permission.Order })
          if (group.order < permission.Order) group.order = permission.Order
        } else items.push({ name: permission.Name, value: permission.Value, order: permission.Order })
      }
      return items.sort((a: any, b: any) => a.order - b.order)
    })

    return {
      grid,
      gridOptions,
      formOptions,
      permissionItems
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
    alertDelete () {
      confirm('是否確定取消指派該產品?')
    }
  }
})
</script>