
/* eslint-disable */
import CloudFun, {
  computed,
  defineComponent,
  ref,
  onMounted
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import CheckBoxList from "@/cloudfun/components/CheckBoxList.vue";
import { integer } from "@vuelidate/validators";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import MemberModal from "@/components/member-edit-modal/Main.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Grid,
    CheckBoxList,
    SelectBox,
    MemberModal
  },
  setup() {
    const model = CloudFun.current?.model;
    const router = useRouter();
    const grid = ref<any>({});
    var printColumns = [{ field: "Name" }, { field: "Description" }];
    const positionList = ref<any>([]);
    const auth = ref<any>({});
    const position = [
      {
        text: "負責人",
        value: "1"
      },
      {
        text: "特助",
        value: "2"
      },
      {
        text: "產品經理",
        value: "3"
      },
      {
        text: "業務",
        value: "4"
      }
    ];

    const gridOptions: GridOptions = {
      id: "member",
      title: "成員",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: "角色清單",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "角色清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "account",
          title: "帳號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "nick_Name",
          title: "暱稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "contact_Title",
          title: "職稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "contact_Tel",
          title: "聯絡電話",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          visible: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "contact_Email",
          title: "業務信箱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "position",
          title: "權限",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          formatter: ({ cellValue }) => {
            let item = null;
            if (cellValue) {
              item = position.find(e => e.value === cellValue.toString())?.text;
            }
            return item ? item : "";
          }
        },
        {
          field: "status",
          title: "狀態",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        // {
        //   field: 'remark',
        //   title: '備註',
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   resizable: false,
        //   editRender: {
        //     name: '$input',
        //     immediate: true,
        //     attrs: { type: 'text' }
        //   }
        // },
        {
          field: "PermissionValues",
          visible: false
        }
      ],
      promises: {
        query: model
          ? params =>
              model.dispatch("member/query", {
                pageSize: params.pageSize,
                page: params.page,
                search: "null"
              })
          : undefined,
        queryAll: model ? () => model.dispatch("member/query") : undefined,
        save: model
          ? params => model.dispatch("member/save", params)
          : undefined,
        insert: model
          ? data => model.dispatch("member/insert", data)
          : undefined,
        delete: model ? id => model.dispatch("member/delete", id) : undefined,
        update: model
          ? data => model.dispatch("member/update", data)
          : undefined
      },
      modalConfig: { showFooter: true, width: "500" }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: "right",
      items: [
        {
          field: "contact_Email",
          title: "業務信箱",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        // {
        //   field: "name",
        //   title: "姓名",
        //   span: 24,
        //   itemRender: {
        //     name: "$input",
        //     props: { placeholder: "請輸入文字", readonly: true },
        //     attrs: { type: "text" }
        //   }
        // },
        {
          field: "contact_Title",
          title: "職稱",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "contact_Tel",
          title: "電話",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        // {
        //   field: 'account',
        //   title: '帳號',
        //   span: 24,
        //   itemRender: {
        //     name: '$input',
        //     props: { placeholder: '請輸入文字', readonly: true },
        //     attrs: { type: 'text' }
        //   }
        // },
        {
          field: "position",
          title: "權限職位",
          span: 24,
          slots: { default: "form-position" }
          // itemRender: {
          //   name: "$select",
          //   options: []
          // }
        },
        {
          field: "enable",
          title: "狀態",
          span: 24,
          itemRender: {
            name: "$select",
            options: [
              { label: "停用", value: false },
              { label: "已啟用", value: true }
            ]
          }
        }
        // {
        //   field: 'contact_Title',
        //   title: '職稱',
        //   span: 12,
        //   itemRender: {
        //     name: '$input',
        //     props: { placeholder: '請輸入文字', clearable: true },
        //     attrs: { type: 'text' }
        //   }
        // },

        // {
        //   title: '負責產品',
        //   span: 24,
        //   slots: { default: 'column-product' }
        // },
        // {
        //   field: 'remark',
        //   title: '備註',
        //   span: 24,
        //   itemRender: {
        //     name: '$input',
        //     props: { placeholder: '請輸入文字', clearable: true },
        //     attrs: { type: 'text' }
        //   }
        // },
        // {
        //   title: "權限",
        //   span: 24,
        //   slots: { default: "form-permissions" }
        // }
      ],
      rules: {
        position: [{ type: "string", required: true }],
        contact_Title: [{ type: "string", required: true }],
        contact_Tel: [
          {
            type: "string",
            message: "格式錯誤",
            required: false,
            pattern: new RegExp(
              "^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$"
            )
          }
        ],
        contact_Email: [
          {
            message: "格式錯誤",
            required: true,
            pattern: new RegExp(
              "^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$"
            )
          }
        ]
      }
    };

    const permissionItems = computed(() => {
      const items: any[] = [];
      for (const permission of Object.values(
        model?.enums.SystemPermission || {}
      )) {
        if (permission.GroupName != null) {
          let group = items.find(e => e.name === permission.GroupName);
          if (!group)
            items.push(
              (group = { name: permission.GroupName, items: [], order: 0 })
            );
          group.items.push({
            name: permission.Name,
            value: permission.Value,
            order: permission.Order
          });
          if (group.order < permission.Order) group.order = permission.Order;
        } else
          items.push({
            name: permission.Name,
            value: permission.Value,
            order: permission.Order
          });
      }
      return items.sort((a: any, b: any) => a.order - b.order);
    });

    const getAuth = () => {
      return model
        ?.dispatch("user/auth")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    onMounted(async () => {
      auth.value = await getAuth();
      if (!auth.value.isBuyer) router.push("/company-not-vendor");
      const positionData = await model?.dispatch("option/find", [
        "Vendor_Position"
      ]);

      if (positionData) {
        positionList.value = Object.values(positionData.Vendor_Position).map(
          (item: any) => {
            return { label: item.text, value: parseInt(item.value) };
          }
        );
      }
    });

    const productSelectorOptions: SelectBoxOptions = {
      rowId: "productID",
      transfer: true,
      placeholder: "選擇",
      textField: "name",
      valueField: "productID",
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value => model!.dispatch("product/find", value), // eslint-disable-line
        query: params =>
          model!.dispatch("product/query", {
            pageSize: params.pageSize,
            page: params.page
          }) // eslint-disable-line
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      permissionItems,
      positionList,
      position,
      productSelectorOptions,
      auth,
      router
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.memberID
        ? await this.$model.dispatch("member/find", row.memberID)
        : undefined;
      if (entity) {
        Object.assign(row, entity);
      }
      callback();
    }
  }
});
